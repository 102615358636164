import React from 'react'
import { Link } from 'gatsby'
import Obfuscate from 'react-obfuscate'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { Nav } from 'components/nav'
import { LinkArrowIcon } from '../components/icons'
import { Activities, Fact500Icon, FactBizIcon, FactItem, FactLoyalIcon, FactXPIcon, ServicesSection } from 'components/pages/IndexPage'
import ImageGallery from 'react-image-gallery'
import { BackgroundWithGrid } from '../components/layout'
import { LdJsonLogo, HeaderLink } from '../components/semanticdata'
import Helmet from 'react-helmet'
import Analytics from '../components/analytics'

const IndexPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO title="Tumba Solutions" pageUrl="/" description="Pure passion for technology and product growth. We are in this together. Have a challenge in mind? Let's talk." />
    <LdJsonLogo />
    <Helmet>
      <meta name="google-site-verification" content="RTi3UZnYM-oDtbhGGFleQ5-SHpJ3r8FgXujsC-RXVQQ" />
    </Helmet>
    <MainWithMenu className="grid">
      <BackgroundWithGrid image="main-hero" gridRow={1} />
      <Header className="z-20 pt-6">
        <HeaderLink />
        <div className="m-auto w-full py-32 md:py-56 text-center">
          <span className="text-3xl sm:text-4xl lg:text-5xl leading-tighter">
            Pure passion for&nbsp;technology and&nbsp;product growth.
            <br />
            We are in this{' '}
            <Link to="/manifesto/" className="text-primary-1">
              together
            </Link>
            .
          </span>
        </div>
      </Header>
      <Deco before after decoType={1} gridRow={2} className="bg-primary-1" />
      <Section className="z-20" gridRow={2}>
        <div className="flex flex-wrap lg:flex-no-wrap">
          <FactItem icon={Fact500Icon} fact="48" desc="% revenue growth" />
          <FactItem icon={FactLoyalIcon} fact="93" desc="% return clients" />
          <FactItem icon={FactBizIcon} fact="36" desc="months per project" />
          <FactItem icon={FactXPIcon} fact="15" desc="years avg. XP" />
        </div>
        <div className="mt-24 pb-12 w-full flex flex-col col-gap-4 lg:flex-row justify-center items-center align-middle text-2xl lg:text-3xl">
          <h1 className="text-shadow">Have a challenge in mind?</h1>
          <Obfuscate email="contact+www@tumba.solutions" headers={{ subject: 'Ask Tumba' }} aria-label="Email us" className="text-primary-2">
            Let's talk.
          </Obfuscate>
        </div>
      </Section>
      <ServicesSection className="z-20 pt-40 relative" gridRow={3} />
      <BackgroundWithGrid image="main-careers" gridRow={4} />
      <Deco before inner decoType={2} gridRow={4} className="z-10" />
      <Section className="z-20 mt-48 mb-48 h-72" gridRow={4}>
        <div className="inset-0 h-full w-full flex flex-col justify-center items-center">
          <h2 className="text-3xl lg:text-5xl leading-tighter text-primary-3 text-shadow">Outsiders, come in</h2>
          <Link to="/careers/" className="mt-4 flex items-baseline">
            <span className="uppercase font-normal tracking-normal text-sm text-primary-1">careers</span>
            <LinkArrowIcon className="ml-2 w-3 text-primary-1 stroke-current" />
          </Link>
        </div>
      </Section>
      <Deco before decoType={1} gridRow={5} className="z-10 bg-primary-1" />
      <Section className="z-20 mx-4 sm:mx-12 my-8 sm:my-40" gridRow={5}>
        <ImageGallery showBullets={true} showNav={false} showPlayButton={false} showFullscreenButton={false} showThumbnails={false} items={Activities} />
      </Section>
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default IndexPage
